import React from 'react'
import styled from 'styled-components'

import GlobalStyles from '../../styles/global'
import { above } from '../../styles'

import { Header, Footer, SecondaryNavigation } from '../index'
import '../../styles/css/fontface.css'

const Layout = ({ children, hero, secondaryNavigation, title, location, isStoreLocator, noBackground }) => {
  return (
    <>
      {/* @todo: move globals to browser / ssr */}
      <GlobalStyles noBackground={noBackground} />
      <Header hero={hero} title={title} location={location} />
      {secondaryNavigation && <SecondaryNavigation secondaryNavigation={secondaryNavigation} location={location} />}
      <Main isStoreLocator={isStoreLocator}>{children}</Main>
      {!isStoreLocator && <Footer />}
    </>
  )
}

const Main = styled.main`
  margin: ${p => (p.isStoreLocator ? '0px' : '50px auto')};
  max-width: ${p => (p.isStoreLocator ? '' : '1392px')};
  min-height: 400px;

  ${above.tablet`
    margin: ${p => (p.isStoreLocator ? '0px' : '92px auto')};
  `}
`

export default Layout
